
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class ErrorModal extends Vue {

    @Prop({
        default: ""
    }) title: string;

    @Prop({
        default: ""
    }) description: string;

    @Prop({
        default: ""
    }) firstButtonText: string;

    @Prop({
        default: ""
    }) secondButtonText: string;

    @Prop({
        default: true
    }) showCloseButton: boolean;

    @Prop() callback1: any;
    @Prop() callback2: any;

    created(){
    }

}
