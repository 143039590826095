
import { Options, Vue } from 'vue-class-component';
import { InternalUserClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';
import ErrorModal from '@/modals/errorModal.vue';

@Options({
    components: {}
})
export default class Login extends Vue {

    model: OM.CultureBaseCredential = new OM.CultureBaseCredential();
    selectedLanguage: string = "";

    created() {
        this.selectedLanguage = StorageServices.getLoggedUser().preferredCulture;
    }

    login() {
        InternalUserClient.login(this.model)
        .then(x => {
            StorageServices.setNewTokens(x.token, x.refreshToken);
            this.$router.push("/dashboard")
        })
        .catch(y => {
            this.$opModal.show(ErrorModal, {
                title: this.$localizationService.getLocalizedValue('app_errore', 'Errore'),
                description: this.$localizationService.getLocalizedValue('app_UtenteNonEsistenteNellaCommunity', 'Utente non esistente nella community'),
                firstButtonText: this.$localizationService.getLocalizedValue('app_Riprova', 'Riprova'),
                callback1: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    get disabled() {
        // return !this.model.email || !this.model.password;
        return false;
    }

}
